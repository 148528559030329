
import { InfoIOFragments } from '@/components/TableComponents/Line/Fragments'
import RetryFastRetryFragment from '@/components/TableComponents/Line/Fragments/RetryFastRetryFragment.vue'
import InstructionGetterSetter from '@/models/Overlay/InstructionGetterSetter'
import RemoveScibidsModel from '@/models/RemoveScibids/RemoveScibidsModel'

import { defineComponent } from 'vue'
import AlertsColumn from '@/components/SurcoucheV2/AlertsColumn.vue'
import HealthColumn from '@/components/SurcoucheV2/HealthColumn.vue'
import LocalizationColumn from '@/components/SurcoucheV2/LocalizationColumn.vue'
import SelectInstructionSurcoucheV2 from '@/components/SurcoucheV2/SelectInstructionSurcoucheV2.vue'
import _ from 'lodash'
import { Instruction } from '../../../types/instruction_type'
import { RawLocation } from 'vue-router'

export default defineComponent({
  name: 'LineIOV2',
  props: ['selectedIos', 'props', 'dsp', 'dataIsLoading', 'alerts', 'groupKeys'],
  components: {
    SelectInstructionSurcoucheV2,
    LocalizationColumn,
    HealthColumn,
    AlertsColumn,
    RetryFastRetryFragment,
    InfoIOFragments
  },
  data: function () {
    return {
      isHover: false,
      insGetterSetter: new InstructionGetterSetter(this.props.item, this.dsp)
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
    selectItemIdAndKpi: function (id: number, instruction: Instruction, state: boolean) {
      const selectedIos = _.cloneDeep(this.selectedIos)
      selectedIos[id] = { instruction, state }
      this.$emit('update:selectedIos', selectedIos)
    },
    askForEditItem (item: string) {
      this.$emit('edit-item', item)
    },
    askForDuplicateIO (item: string) {
      this.$emit('duplicate-io', item)
    },
    askForOpenStrat (insertionOrderId: string) {
      this.$emit('open-strat', insertionOrderId)
    },
    removeScibids () {
      let removeScibidsData = {
        ioID: this.props.item.io,
        memberExtID: this.insGetterSetter.clientIdPerDsp,
        dsp: this.dsp,
        removeScibidsModel: new RemoveScibidsModel()
      }
      this.$emit('removeScibids', removeScibidsData)
    },
    openIo () {
      this.$router.push(this.ioPageRoute)
    },
    openIoNewTab () {
      window.open(this.$router.resolve(this.ioPageRoute).href, '_blank')
    },
    getGroupKeyPerLineIO (io: string) {
      for (let groupKey in this.groupKeys) {
        let insertionOrderId = this.groupKeys[groupKey].group_key.insertion_order_id
        if (insertionOrderId === io) {
          return this.groupKeys[groupKey]
        }
      }
    }
  },
  computed: {
    checkBoxSelected: function () :boolean {
      return this.selectedIos[this.props.item.id] && this.selectedIos[this.props.item.id].state
    },

    ioPageRoute (): RawLocation {
      return {
        name: 'ioPage',
        params: { dsp: this.dsp, external_id: this.props.item.io }
      }
    }
  },
  watch: {
  }
})
